export const getFilteredSearchForHotelLink = (parsedSearch: object) => {
    let kidsAdded = false;
    const fields = ['adults', 'arrival_date', 'departure_date', 'rooms', 'place_id', 'place_name'];

    const filteredSearchStringForLink = new URLSearchParams('');

    Object.keys(parsedSearch).forEach((key: string) => {
        if (fields.includes(key)) {
            filteredSearchStringForLink.set(key, parsedSearch[key] || '');
        }

        if (key === 'kids') {
            parsedSearch['kids'].forEach((item: string) => {
                if (kidsAdded) {
                    filteredSearchStringForLink.append('kids[]', item);
                } else {
                    kidsAdded = true;
                    filteredSearchStringForLink.set('kids[]', item);
                }
            })
        }
    })

    return filteredSearchStringForLink.toString().replace('?', '');
}
